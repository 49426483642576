.jss130 {
    margin: auto;
    display: flex;
    outline: 0;
    position: relative;
    justify-content: center;
}

.overflow-box {
    width: 69%;
    height: 169px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    padding: 9px;
    margin: 0 auto;
}