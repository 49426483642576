.device-status {
  /* color: rgb(4, 158, 81); */
  font-size: 24px;
  font-weight: 600;
}
.device-status-label {
  /* color: rgb(4, 158, 81); */
  font-size: 8px;
  font-style: italic;
}
.lblLeftLabel {
  font-size: 8px;
  float: left;
}
.device-item .MuiTypography-h5 {
  font-size: 1rem;
}
.device-item .MuiTypography-body1 {
  font-size: 0.6rem;
}
#device-list-wrapper .MuiCardHeader-root {
  padding: 0px 16px 0px;
}
#device-list-wrapper .MuiCardContent-root {
  padding: 0px 16px 0px;
}
.fix-length-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 89px;
  font-size: 9px;
}
.float-left {
  float: left;
}
.box-status-wrapper {
  margin-bottom: 10px;
}
.filter-box {
  border: 0px solid #383e5d;
  padding: 2px 15px 2px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: #2a2c44;
}
.control-box {
  width: 100%;
  padding: 2px;
}
.chip-label {
  /* color: rgba(0, 0, 0, 0.87); */
  border: 1px solid #ddd;
  /* cursor: default; */
  /* padding: 3px; */
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 4px 6px 2px;
  font-size: 0.8125rem;
  box-sizing: border-box;
  /* transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  align-items: center;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  /* white-space: nowrap; */
  border-radius: 4px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  /* background-color: #ddd; */
  margin-bottom: 8px;
  /* margin-left: 6px; */
  /* width: 69%; */
  margin-right: 6px;
}
.padding9-icon {
  margin-left: 9px;
}
.online {
  /* color: rgb(4, 158, 81); */
  color: #fff;
}
.MuiCard-root {
  color: #fff !important;
  background-color: #262446 !important;
}
.error-item {
  border: 2px solid red;
}
.react-sweet-progress-symbol {
  width: 24px !important;
  height: 12px !important;
}
.filter-label {
  margin-right: 5px;
  font-size: 9px;
}
.padding-left {
  float: left;
}
.info-label {
  font-size: 10px;
}
.MuiIconButton-label {
  color: #ddd !important;
}
.makeStyles-paperTitle-13 {
  color: #ddd;
}
.MuiTypography-colorTextSecondary {
  color: #ddd !important;
}
.severity-good {
  color: #3bd949;
}
.severity-moderate {
  color: #ffd500;
}
.severity-sensitive-unhealthy {
  color: #ff7e00;
}
.severity-unhealthy {
  color: #ff0017;
}
.severity-very-unhealthy {
  color: #99004c;
}
.severity-hazardous {
  color: #7e0023;
}
.round-border {
  background-color: #384467;
  /* color: #ffffff; */
  padding: 4px 6px 2px 6px;
  /* padding: 4px 6px 0px 0px; */
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 5px;
}

.round-border-small {
  background-color: #384467;
  /* color: #ffffff; */
  padding: 6px 6px 0px 6px;
  /* padding: 4px 6px 0px 0px; */
  border-radius: 38px;
  display: inline-block;
  cursor: pointer;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 5px;
}
.darkmod-disabled {
  color: rgba(255, 255, 255, 0.3) !important;
}
.info-label {
  color: #ddd;
}