.margin-btn {
    margin: 8px;
}
.filter-select {
    cursor: pointer;
    margin-bottom: 2px;
}
.MuiPaper-root {
    color: #fff !important;
}
.MuiFormControlLabel-label.Mui-disabled {
    color: rgba(255, 255, 255, 0.3) !important;
}
.MuiButton-root.Mui-disabled {
    color: rgba(255, 255, 255, 0.3) !important;
}