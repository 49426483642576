.active-row {
    cursor: pointer;
}
.container {
    overflow-x: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    padding: 10px;
    margin: 10px;
  }
  