
@media (min-width: 600px) {
    .MuiToolbar-regular {
        min-height: 45px !important;
    }
    .makeStyles-drawerHeader-28 {
        min-height: 45px !important;
    }
}

.right-area {
    margin-left: auto;
}

.MuiListItemIcon-root {
    color: #3f51b5 !important;
}

.left-button{
    color: #3f51b5 !important;
}

.MuiAppBar-colorPrimary {
    background-color: #222437 !important;
}

.MuiPaper-root {
    background-color: #222437 !important;
}